/** @jsx jsx */
import * as React from "react";
import { useRef, useState, useEffect } from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import styled from "@emotion/styled";
import { jsx, css } from "@emotion/react";
import { colors, screenSize, quicksandFont } from "../../utils/css/themes";
// import Caret from "../../utils/images/Nav/icon-carat.inline.svg";

const Header = styled.button`
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0;
  margin: 0;
  display: flex;
  border-width: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${screenSize.smallTablet}) {
    flex-direction: row;
  }
`;
const AccordionContainer = styled.div`
  box-shadow: 4px 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
  border-radius: 5px;
`;

const AccordionContent = styled.div`
  overflow: hidden;
  height: 0;
  font-family: ${quicksandFont.regular};
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  line-height: 2.5;
  display: flex;
  align-items: start;
  color: ${colors.blue};
  a {
    color: ${colors.blue};
  }
  transition: height 0.25s;
`;

const Accordion = (props: PropsAccordion) => {
  const [contentHeight, setContentHeight] = useState(null);
  const contentRef = useRef(null);

  // on screen resize
  // update height of content
  // to allow smooth height transition on click
  const contentResize = () => {
    setContentHeight(contentRef.current.scrollHeight);
  };

  // listen for screen resize
  useEffect(() => {
    setContentHeight(contentRef.current.scrollHeight);
    contentResize();
    window.addEventListener("resize", contentResize);
    return () => {
      window.removeEventListener("resize", contentResize);
    };
  });
  // listen for screen resize

  const toggleClick = () => props.setIsOpen(!props.isOpen);
  
  const getPadding = () => {
    if (props.isFullWidth) {
      return "0";
    } else if (props.isleftPadded) {
      return "0 16px";
    } 
    return "0 24px";
  };

  return (
    <AccordionContainer>
      <Header onClick={() => toggleClick()}>{props.title}</Header>
      <AccordionContent
        css={css`
          height: ${props.isOpen ? (contentHeight + 20) + "px" : "0px"};
          visibility: ${props.isOpen ? "none" : "collapse"};
          position: ${props.isAbsolute ? "absolute" : "relative"};
          transition: height 0.25s;
          padding: ${getPadding()};
        `}
        ref={contentRef}
      >
        {props.children}
      </AccordionContent>
    </AccordionContainer>
  );
};

interface PropsAccordion {
  children: any;
  title: any;
  isOpen: boolean;
  setIsOpen: any;
  isAbsolute?: boolean;
  isFullWidth?: boolean;
  isleftPadded?: boolean;
}

export default Accordion;
